<template>
    <div>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle" :visible.sync="showModel"
            :destroy-on-close="true" width="80%" center>
            <el-tabs v-model="searchType" @tab-click="searchFun">
                <el-tab-pane :label="row.title" :name="row.id" v-for="(row, index) in tabData" :key="index"></el-tab-pane>
            </el-tabs>
            <div class="d-flex mt10 mb10">
                <div>
                    <el-input class="w400 mr10" placeholder="请输入模板名称" v-model="searchData.name" />
                    <el-button type="primary" @click="searchFun">搜索</el-button>
                </div>
            </div>
            <el-table v-loading="loading" class="tableRadio" ref="multipleTable" :data="tableData" border lay
                tooltip-effect="dark" @current-change="selectData" :highlight-current-row="true">
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="name" align="center" label="模板名称" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="userName" align="center" label="提交用户" show-overflow-tooltip width="120" />
                <el-table-column prop="fileUrl" align="center" label="类型" show-overflow-tooltip width="120">
                    <template slot-scope="scope">
                        {{ typeData[scope.row.modelType] }}
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" align="center" label="上传时间" show-overflow-tooltip width="200" />
            </el-table>
            <div style="display: flex; justify-content: flex-end; margin-top: 16px" v-if="tableData.length !== 0">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[10, 15, 20]" layout="total, sizes, prev, pager, next, jumper"
                    class="" :total="total" :pager-count="5" />
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getWordModelList } from "@/api/projectAdmin";
export default {
    data() {
        return {
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [],
            searchData: { name: "" },
            //模态框配置
            modelTitle: "点击选择项目模板",
            checkedData: {},
            tabData: [
                { id: 'all', title: "全部" },
                { id: '1', title: "立项报告" },
                { id: '2', title: "可行性报告" },
                { id: '3', title: "立项决议" },
                { id: '4', title: "测试报告" },
                { id: '5', title: "结题报告" }
            ],
            typeData: {
                0: "其他", 1: "立项报告", 2: "可行性报告", 3: "立项决议", 4: "测试报告", 5: "结题报告"
            },
            searchType: "all",
        };
    },
    filters: {

    },
    created() {

    },
    methods: {
        showModelFun() {
            this.showModel = true;
            //获取项目列表
            this.getList();
        },
        hideModelFun() {
            this.showModel = false;
        },
        selectData(data) {
            this.checkedData = data;
            this.$emit("confirmFun", this.checkedData);
        },
        /**@method 搜索 */
        searchFun() {
            this.getList();
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                name: this.searchData.name
            };
            if (this.searchType !== 'all') {
                params.modelType = this.searchType;
            }
            try {
                this.loading = true;

                let result = await getWordModelList(params)
                const { data } = result;
                this.loading = false;
                this.tableData = data.pageInfo.list;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        }
    },
};
</script>

<style lang="scss" scoped></style>