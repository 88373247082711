<template>
    <div>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle" :visible.sync="showModel"
            :destroy-on-close="true" width="80%" center>
            <div>
                <el-tabs v-model="year" @tab-click="searchFun">
                    <el-tab-pane label="全部" name="all"></el-tab-pane>
                    <el-tab-pane :label="String(y) + '年'" :name="String(y)" v-for="(y, index) in yearData"
                        :key="index"></el-tab-pane>
                </el-tabs>
            </div>
            <div class="d-flex mt20 mb20">
                <div>
                    <el-input class="w400 mr10" placeholder="请输入研发项目名称" v-model="searchData.name" />
                    <el-button type="primary" @click="searchFun">搜索</el-button>
                </div>
            </div>
            <el-table class="tableRadio" v-loading="loading" ref="multipleTable" :data="tableData" border lay
                tooltip-effect="dark" @current-change="selectData" :highlight-current-row="true">
                <el-table-column prop="projectNo" align="center" label="项目序号" width="80" />
                <el-table-column prop="startDay" align="center" label="年份" show-overflow-tooltip width="60">
                    <template slot-scope="scope">
                        {{ scope.row.startDay ? scope.row.startDay.slice(0, 4) : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="projectName" align="center" label="项目名称" show-overflow-tooltip min-width="150" />
                <el-table-column prop="money" align="center" label="预算金额(万元)" show-overflow-tooltip width="120">
                    <template slot-scope="scope">
                        {{ scope.row.money ? scope.row.money : '0' }}
                    </template>
                </el-table-column>
                <el-table-column prop="education" align="center" label="研发起止时间" show-overflow-tooltip width="190">
                    <template slot-scope="scope">
                        {{ scope.row.startDay && scope.row.endDay ? scope.row.startDay + ' - ' + scope.row.endDay : '-'
                        }}
                    </template>
                </el-table-column>
                <el-table-column prop="headName" align="center" label="项目负责人" show-overflow-tooltip width="100">
                    <template slot-scope="scope">
                        {{ scope.row.headName ? scope.row.headName : '-' }}
                    </template>
                </el-table-column>
                <el-table-column prop="proposerName" align="center" label="申请人" show-overflow-tooltip width="80">
                    <template slot-scope="scope">
                        {{ scope.row.proposerName ? scope.row.proposerName : '-' }}
                    </template>
                </el-table-column>
                <el-table-column prop="job" align="center" label="项目组成员" show-overflow-tooltip min-width="150">
                    <template slot-scope="scope">
                        <div class="d-flex">
                            <div class="ma5" v-for="(item, key) in scope.row.projectUserList" :key="key">{{
                                item.userName
                            }};</div>
                        </div>

                    </template>
                </el-table-column>
                <el-table-column prop="testName" align="center" label="检测或测试人" show-overflow-tooltip width="120">
                    <template slot-scope="scope">
                        {{ scope.row.testName ? scope.row.testName : "-" }}
                    </template>
                </el-table-column>
            </el-table>
            <div style="display: flex; justify-content: flex-end; margin-top: 16px" v-if="tableData.length !== 0">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[10, 15, 20]" layout="total, sizes, prev, pager, next, jumper"
                    class="" :total="total" :pager-count="5" />
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import { getProjectDataList } from "@/api/projectAdmin";
export default {
    data() {
        return {
            //列表配置
            yearData: [],
            year: "all",
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [],
            searchData: { name: "" },
            //模态框配置
            modelTitle: "点击选择研发项目",
            btnLoading: false,
            checkedData: {},
            checkedCompany: {},
        };
    },
    filters: {

    },
    mounted() {
        //获取年份
        this.getYearData();
    },
    methods: {
        showModelFun() {
            let checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
            this.checkedCompany = checkedCompany ? checkedCompany : {};
            this.showModel = true;
            //获取项目列表
            this.getList();
        },
        hideModelFun() {
            this.showModel = false;
        },
        selectData(data) {
            this.checkedData = data;
            this.$emit("confirmFun", this.checkedData);
        },
        /**@method 获取最近五年 */
        getYearData() {
            let yearData=[];
let F = new Date().getFullYear();
            for (let i = 0; i <= 4; i++) {
                yearData.push(F - i);
            }
this. yearData= yearData;
        },
        /**@method 搜索 */
        searchFun() {
            this.getList();
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                userName: this.searchData.name
            };
            if (this.year != 'all') {
                params.selectYear = this.year
            }
            if (!this.checkedCompany.pcId) {
                this.tableData = [];
                this.total = 0
                return;
            } else {
                params.pcId = this.checkedCompany.pcId;
            }
            try {
                this.loading = true;

                let result = await getProjectDataList(params)
                const { data } = result;
                this.loading = false;
                this.tableData = data.pageInfo.list;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        }
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>