import { render, staticRenderFns } from "./modelTable.vue?vue&type=template&id=58a1a474&scoped=true&"
import script from "./modelTable.vue?vue&type=script&lang=js&"
export * from "./modelTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58a1a474",
  null
  
)

export default component.exports